exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-wallpaper-tsx": () => import("./../../../src/pages/wallpaper.tsx" /* webpackChunkName: "component---src-pages-wallpaper-tsx" */),
  "component---src-templates-blog-page-tsx-content-file-path-opt-build-repo-src-blog-getting-started-with-open-source-mdx": () => import("./../../../src/templates/blog-page.tsx?__contentFilePath=/opt/build/repo/src/blog/getting-started-with-open-source.mdx" /* webpackChunkName: "component---src-templates-blog-page-tsx-content-file-path-opt-build-repo-src-blog-getting-started-with-open-source-mdx" */),
  "component---src-templates-blog-page-tsx-content-file-path-opt-build-repo-src-blog-my-cooking-journey-mdx": () => import("./../../../src/templates/blog-page.tsx?__contentFilePath=/opt/build/repo/src/blog/my-cooking-journey.mdx" /* webpackChunkName: "component---src-templates-blog-page-tsx-content-file-path-opt-build-repo-src-blog-my-cooking-journey-mdx" */),
  "component---src-templates-blog-page-tsx-content-file-path-opt-build-repo-src-blog-surviving-the-dining-hall-mdx": () => import("./../../../src/templates/blog-page.tsx?__contentFilePath=/opt/build/repo/src/blog/surviving-the-dining-hall.mdx" /* webpackChunkName: "component---src-templates-blog-page-tsx-content-file-path-opt-build-repo-src-blog-surviving-the-dining-hall-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-audio-loader-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/audio-loader.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-audio-loader-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-bitbot-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/bitbot.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-bitbot-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-css-loader-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/css-loader.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-css-loader-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-decky-loader-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/decky-loader.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-decky-loader-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-decky-loader-website-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/decky-loader-website.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-decky-loader-website-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-docere-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/docere.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-docere-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-dreadlight-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/dreadlight.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-dreadlight-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-old-portfolio-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/old-portfolio.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-old-portfolio-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-payday-2-stealth-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/payday-2-stealth.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-payday-2-stealth-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-pugrunner-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/pugrunner.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-pugrunner-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-shodo-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/shodo.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-shodo-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-temoc-bot-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/temoc-bot.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-temoc-bot-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-using-vr-for-learning-hardware-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/using-vr-for-learning-hardware.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-using-vr-for-learning-hardware-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-utdesign-makerspace-trainings-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/utdesign-makerspace-trainings.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-utdesign-makerspace-trainings-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-utdesign-makerspace-website-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/utdesign-makerspace-website.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-utdesign-makerspace-website-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-utdesign-makerspace-wiki-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/src/projects/utdesign-makerspace-wiki.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-opt-build-repo-src-projects-utdesign-makerspace-wiki-mdx" */)
}

